import React from 'react';

const CV = () => {
    return (
        <div>
            <h1 className='title'>Voici mon CV</h1>
            <p>Je dois encore m'occuper de ça.</p>
        </div>
    );
};

export default CV;