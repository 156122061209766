import React from 'react';

const Home = () => {
    return (
        <div>
            <h1 className='title'>Bienvenue sur mon site web !</h1>
            <p>C'est un gros chantier, mais je travaille activement pour le rendre fonctionnel.</p>
        </div>
    );
};

export default Home;