import React from 'react';

const About = () => {
    return (
        <div>
            <h1 className='title'>À propos de moi !</h1>
            <p>Je dois encore m'occuper de ça.</p>
        </div>
    );
};

export default About;
